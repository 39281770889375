import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axiosInstance from "../../axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { FaInstagram, FaFacebook, FaYoutube, FaTiktok, FaLinkedin, FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Navbar from "../../Components/Navbar";

const Home = () => {
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [properties, setProperties] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getProperties();
    const handleScroll = () => {
      setNavbarScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  async function getProperties() {
    const response = await axiosInstance.get("/properties");
    setProperties(response.data);
  }

  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  function goFilter(keyword) {
    navigate(`/filter?keyword=${keyword}`);
  }

  function goStatus(status) {
    navigate(`/filter?status=${status}`);
  }

  return (
    <>
      <div className="relative h-screen overflow-hidden">
        <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted playsInline>
          <source src="bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute inset-0 bg-black opacity-35"></div>

        {/* <nav
          className={`${
            navbarScrolled ? "bg-black" : "bg-transparent"
          } fixed top-0 left-0 w-full z-20 p-5 flex  justify-between items-center transition-colors duration-300`}
        >
          <a href="/">
            <img src="logo.png" alt="Logo" className="w-48 md:w-52" />
          </a>
          <ul className="flex space-x-4 items-center text-white mt-3 md:mt-0">
            <li>
              <a href="https://www.instagram.com/advance.level.real.estate" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={24} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/share/UBf5De2QdPQg7t3s" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={24} />
              </a>
            </li>
            <li>
              <a href="https://youtube.com/@advancelevelrealestate" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@advance.levelrealestate?_t=8qD60q1BiTd&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/advance-level-real-estate-615a5632b"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size={24} />
              </a>
            </li>
          </ul>
        </nav> */}
        <Navbar isHome={true} />
        <div className="relative z-10 flex flex-col items-center justify-center top-44 text-white text-center">
          <h1 className="text-5xl md:text-7xl font-bold">
            <p className="mb-3 uppercase">Is where your story begin</p>
          </h1>
          <div className="blur-header w-11/12 md:w-3/5">
            <div className=" flex gap-2 flex-wrap items-center justify-center">
              <input
                type="text"
                placeholder="City, Community or Area"
                className="px-6 p-3 w-full text-black md:w-4/5"
              />
              <button
                onClick={(e) => goFilter(e.target.previousElementSibling.value)}
                className="bg-black text-white px-5 py-3"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <section id="featured-properties" className="mt-10">
        <div className="max-w-7xl mx-auto px-4">
          <p className="text-3xl font-bold uppercase text-center mb-5">Featured Properties</p>
          <Link className="mb-4 block border w-fit px-5 py-2 border-gray-600 rounded" to="/filter">
            All Prodperties
          </Link>

          {/* <Slider {...sliderSettings} className="mt-4"> */}
          <div className="home-properties-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
            {properties
              ?.slice()
              .reverse()
              .slice(0, 6)
              .map((property) => (
                <div
                  key={property.id}
                  className="px-2 cursor-pointer "
                  onClick={() => navigate(`/property/${property.id}`)}
                >
                  <div className="property-card-home relative">
                    <p className="bg-gray-800 text-white text-sm absolute p-3 rounded right-4 top-5">
                      {property.category.name}
                    </p>
                    <LazyLoadImage
                      alt="Property Image.."
                      className="rounded w-full h-[200px] md:h-[300px] object-cover"
                      src={`https://api.advancelevelrealestate.com/storage/${property.main_image}`}
                      effect="opacity"
                    />
                    <div id="info" className="mt-3 space-y-2">
                      <p className="text-xl font-semibold">
                        {property.currency} {Number(property.price).toLocaleString()} | {property.status}
                      </p>
                      <p className="font-semibold text-sm">{property.name}</p>
                      <p className="text-sm">{property.location}</p>
                    </div>
                    <div className="flex gap-4 items-center justify-center mt-4  pb-4">
                      <a
                        href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                      >
                        <FaWhatsapp className="mr-2" /> WHATSAPP
                      </a>
                      <a
                        href="tel:+971582592907"
                        className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                      >
                        <FaPhoneAlt className="mr-2" /> PHONE
                      </a>
                      <a
                        href="mailto:info@advancelevelrealestate.com"
                        className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                      >
                        <MdOutlineMail className="mr-2" /> EMAIL
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            {/* </Slider> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
