import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import "./App.css";
import Property from "./Pages/Property/Property";
import Properties from "./Pages/Properties/Properties";
import { FaWhatsapp } from "react-icons/fa";
import NotFound from "./Components/NotFound";
import InvestmentAdvisory from "./Static/InvestmentAdvisory";
import PropertyManagement from "./Static/PropertyManagement";
import PropertValuation from "./Static/PropertValuation";
import HolidayHomes from "./Static/HolidayHomes";
import OurPartners from "./Static/OurPartners";
import Footer from "./Components/Footer";
import Contact from "./Static/Contact";
import { PiWhatsappLogoThin } from "react-icons/pi";
import Land from "./Static/Land";
function App() {
  const [smallW, setSmallW] = useState(false);
  function handleClose() {
    setSmallW(true);
  }
  const isMobile = window.innerWidth <= 1024; // تحديد حالة العرض على الجوال
  useEffect(() => {
    if (isMobile) {
      setSmallW(true);
    }
  });
  return (
    <Router>
      {/* المسارات الخاصة بالموقع */}
      <Routes>
        {/* صفحة الرئيسية */}
        <Route path="/" element={<Home />} />

        {/* صفحة عرض العقارات */}
        <Route path="/property/:id" element={<Property />} />

        <Route path="/filter" element={<Properties />} />
        <Route path="*" element={<NotFound />} />

        {/* STATIC PAGES */}
        <Route path="/investment-advisory" element={<InvestmentAdvisory />} />
        <Route path="/property-management" element={<PropertyManagement />} />
        <Route path="/property-valuation" element={<PropertValuation />} />
        <Route path="/holiday-homes" element={<HolidayHomes />} />
        <Route path="/our-partners" element={<OurPartners />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact-property" element={<Land />} />

        {/* يمكنك إضافة المزيد من المسارات هنا */}
      </Routes>

      <Footer />
      {smallW ? (
        <a
          href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed right-12 bottom-10  bg-green-400 text-2xl lg:text-5xl p-4 text-white rounded-full"
        >
          <PiWhatsappLogoThin />
        </a>
      ) : (
        <div className="fixed right-12 bottom-10  bg-green-400 w-80 text-white flex flex-col  items-center justify-center p-4 rounded">
          <button onClick={handleClose} className="absolute inline-block top-2 right-5 text-2xl font-bold">
            X
          </button>
          <FaWhatsapp className="text-4xl mb-2" />
          <p className="text-lg font-semibold">Chat with us on Whatsapp!</p>
          <p className="text-xs">Get Your Question answered right away.</p>
          <a
            href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white shadow text-center rounded text-sm text-black font-semibold w-full p-2 mt-2"
          >
            Chat Now
          </a>
        </div>
      )}
    </Router>
  );
}

export default App;
