import React from "react";
import Navbar from "../Components/Navbar";
import homeBG from "../assets/images/home-holiday.jpg";

function HolidayHomes() {
  return (
    <>
      <Navbar />
      <div
        className="top-28 relative text-white p-10 h-[1000px] flex items-center"
        style={{ backgroundImage: `url(${homeBG})`, backgroundSize: "cover" }}
      >
        <div className="container m-auto p-5 bg-black bg-opacity-55">
          <h1 className="text-center text-4xl font-semibold w-fit m-auto border-b-4 border-gray-800 pb-3">
            Holiday Homes And Rentings
          </h1>
          <div className="space-y-5 mt-12 text-lg">
            <p>A-living Holiday Homes in Dubai</p>
            <p>
              The ideal solution for investors who want to increase the return from their properties through daily,
              weekly
              <p>Find your vacation home in the Vicinity of Dubai’s Famous Landmarks.</p>
              and monthly rentals.
            </p>
            A wide selection of experiences curated for your next trip. Create wonderful moments with your loved ones
            through
            <p>
              Find your vacation home in the Vicinity of Dubai’s Famous Landmarks. A wide selection of experiences
              curated for your next trip. Create wonderful moments with your loved ones through
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HolidayHomes;
