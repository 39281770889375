import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import axiosInstance from "../axios";
import { FaCheckCircle } from "react-icons/fa";
import { CiPhone } from "react-icons/ci";
import { LuSend } from "react-icons/lu";

function Land() {
  const [formData, setFormData] = useState({
    customer_name: "",
    phone: "",
    customer_email: "",
    country_code: "+971", // قيمة افتراضية لكود الدولة
    message: "",
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Modal للحالة الناجحة

  const [successMessage, setSuccessMessage] = useState("");
  const countryCodes = [
    { name: "United States", code: "+1" },
    { name: "Canada", code: "+1" },
    { name: "United Kingdom", code: "+44" },
    { name: "Germany", code: "+49" },
    { name: "France", code: "+33" },
    { name: "Italy", code: "+39" },
    { name: "Spain", code: "+34" },
    { name: "Australia", code: "+61" },
    { name: "India", code: "+91" },
    { name: "China", code: "+86" },
    { name: "Japan", code: "+81" },
    { name: "Russia", code: "+7" },
    { name: "Brazil", code: "+55" },
    { name: "Mexico", code: "+52" },
    { name: "Argentina", code: "+54" },
    { name: "South Africa", code: "+27" },
    { name: "Nigeria", code: "+234" },
    { name: "Egypt", code: "+20" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "United Arab Emirates", code: "+971" },
    { name: "Qatar", code: "+974" },
    { name: "Kuwait", code: "+965" },
    { name: "Oman", code: "+968" },
    { name: "Bahrain", code: "+973" },
    { name: "Turkey", code: "+90" },
    { name: "Pakistan", code: "+92" },
    { name: "Bangladesh", code: "+880" },
    { name: "Malaysia", code: "+60" },
    { name: "Indonesia", code: "+62" },
    { name: "Philippines", code: "+63" },
    { name: "Thailand", code: "+66" },
    { name: "South Korea", code: "+82" },
    { name: "Vietnam", code: "+84" },
    { name: "New Zealand", code: "+64" },
    { name: "Singapore", code: "+65" },
    { name: "Hong Kong", code: "+852" },
    { name: "Israel", code: "+972" },
    { name: "Morocco", code: "+212" },
    { name: "Kenya", code: "+254" },
    { name: "Ghana", code: "+233" },
    { name: "Colombia", code: "+57" },
    { name: "Chile", code: "+56" },
    { name: "Venezuela", code: "+58" },
    { name: "Peru", code: "+51" },
    { name: "Bolivia", code: "+591" },
    { name: "Paraguay", code: "+595" },
    { name: "Uruguay", code: "+598" },
    { name: "Iraq", code: "+964" },
    { name: "Syria", code: "+963" },
    { name: "Jordan", code: "+962" },
    { name: "Lebanon", code: "+961" },
    { name: "Afghanistan", code: "+93" },
    { name: "Iran", code: "+98" },
    { name: "Sri Lanka", code: "+94" },
    { name: "Nepal", code: "+977" },
    { name: "Maldives", code: "+960" },
    { name: "Myanmar", code: "+95" },
    { name: "Cambodia", code: "+855" },
    { name: "Laos", code: "+856" },
    { name: "Mongolia", code: "+976" },
    { name: "Tanzania", code: "+255" },
    { name: "Uganda", code: "+256" },
    { name: "Zimbabwe", code: "+263" },
    { name: "Zambia", code: "+260" },
    { name: "Ethiopia", code: "+251" },
    { name: "Somalia", code: "+252" },
    { name: "Sudan", code: "+249" },
    { name: "Libya", code: "+218" },
    { name: "Tunisia", code: "+216" },
    { name: "Algeria", code: "+213" },
    { name: "Ivory Coast", code: "+225" },
    { name: "Cameroon", code: "+237" },
    { name: "Angola", code: "+244" },
    { name: "Senegal", code: "+221" },
    { name: "Mozambique", code: "+258" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false); // أغلق مودال النجاح
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/request-contact", {
        ...formData,
        customer_phone: formData.country_code + formData.phone,
      });
      setSuccessMessage("Thank you, one of our advisers will contact you shortly.");
      setIsSuccessModalOpen(true); // افتح مودال النجاح
      setFormData({
        customer_name: "",
        phone: "",
        customer_email: "",
        country_code: "+971",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting booking:", error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="top-28 relative contact-container p-10 h-full flex items-center justify-center">
        <div className=" p-10 bg-black bg-opacity-55  text-white ">
          <div className="grid grid-cols-1">
            <div className="p">
              <h1 className="text-lg font-semibold text-gray-100">Send us a message</h1>
              <p className="text-sm font-extralight text-gray-300 mb-2">
                A member of our team will be in touch within 30 minutes.
              </p>
              <form onSubmit={handleSubmit} className="flex flex-col flex-wrap gap-4 text-black">
                <input
                  type="text"
                  name="customer_name"
                  placeholder="Name"
                  value={formData.customer_name}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 px-4 py-2"
                />
                <div className="flex items-center">
                  <select
                    name="country_code"
                    className="border border-gray-300 rounded p-2 mr-2 w-1/3"
                    value={formData.country_code}
                    onChange={handleChange}
                  >
                    {countryCodes.map((country, index) => (
                      <option key={index} value={country.code}>
                        {country.name} ({country.code})
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone"
                    className="border border-gray-300 rounded p-2 w-2/3"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="customer_email"
                  placeholder="Email (Optional)"
                  value={formData.customer_email}
                  onChange={handleChange}
                  className="border border-gray-300 px-4 py-2"
                />
                <textarea
                  onChange={handleChange}
                  className="border border-gray-300 px-4 py-2 rounded"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                ></textarea>
                <button
                  type="submit"
                  className="bg-zinc-900 flex items-center justify-center text-white p-3 text-sm font-light   rounded transition hover:bg-gray-800 mt-2"
                >
                  <LuSend className="mr-2" />
                  Submit
                </button>
                <a
                  href={`tel:+971582592907`}
                  className="bg-zinc-900 flex items-center justify-center text-white p-3 text-sm font-light   rounded transition hover:bg-gray-800 mt-2"
                >
                  <CiPhone className="text-xl mr-2" />
                  Call Now
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="relative w-full min-h-screen top-16 contact-container font-bold text-white bg-black md:flex md:flex-row">
        <div className="w-full md:w-1/2 p-5 md:p-10">
          <h1 className="uppercase text-2xl font-bold">
            Get in touch with Advance Level Real Estate
          </h1>
          <p className="mt-8 font-extralight">
            Our team of experts will be able to get you the right information about prices and properties here in Dubai. We are a global real estate advisory firm which helps customers connect with the right investor.
          </p>
          <div id="contact-item" className="pb-3 border-b mt-8">
            <p className="text-gray-300 font-bold mb-2 text-sm">Phone</p>
            <a href="tel:+971582592907" className="text-gray-200">+971-582-592-907</a>
          </div>
          <div id="contact-item" className="pb-3 border-b mt-8">
            <p className="text-gray-300 font-bold mb-2 text-sm">Whatsapp</p>
            <a
              href={`https://wa.me/${"+971585408409"}`}
              target="_blank"
              rel="noreferrer"
              className="uppercase duration-300 text-gray-200"
            >
              Click To Whatsapp
            </a>
          </div>
          <div id="contact-item" className="pb-3 border-b mt-8">
            <p className="text-gray-300 font-bold mb-2 text-sm">Email</p>
            <a href="mailto:marketing@advancelevelrealestate.com" className="uppercase text-gray-200">
              marketing@advancelevelrealestate.com
            </a>
          </div>
          <div id="contact-item" className="pb-3 border-b mt-8">
            <p className="text-gray-300 font-bold mb-2 text-sm">Office</p>
            <p className="uppercase text-gray-200">Dubai, Alsafi, 1, Tower</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-5 md:p-10">
          <h1 className="text-lg font-semibold text-gray-100">Send us a message</h1>
          <p className="text-sm font-extralight text-gray-300">
            A member of our team will be in touch within 30 minutes.
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-8">
            <input
              type="text"
              name="customer_name"
              placeholder="Name"
              value={formData.customer_name}
              onChange={handleChange}
              required
              className="border border-gray-300 px-4 py-2 rounded"
            />
            <div className="flex flex-wrap items-center gap-4">
              <select
                name="country_code"
                className="border border-gray-300 rounded p-2 w-full sm:w-1/3"
                value={formData.country_code}
                onChange={handleChange}
              >
                {countryCodes.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="phone"
                placeholder="Your Phone"
                className="border border-gray-300 rounded p-2 w-full sm:w-2/3"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <input
              type="email"
              name="customer_email"
              placeholder="Email (Optional)"
              value={formData.customer_email}
              onChange={handleChange}
              className="border border-gray-300 px-4 py-2 rounded"
            />
            <textarea
              onChange={handleChange}
              className="border border-gray-300 px-4 py-2 rounded"
              name="message"
              placeholder="Message"
              value={formData.message}
            ></textarea>
            <button
              type="submit"
              className="bg-zinc-900 text-white p-3 rounded transition hover:bg-gray-800 mt-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div> */}
      {isSuccessModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg flex flex-col items-center">
            <FaCheckCircle className="text-green-500 text-4xl mb-4" />
            <p className="text-lg text-center">{successMessage}</p>
            <button onClick={closeSuccessModal} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Land;
