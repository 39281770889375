import React from 'react';

const PropertySkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="mb-4">
        <div className="h-8 bg-gray-200 rounded w-3/4"></div>
        <div className="mt-2 h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="h-64 bg-gray-200 rounded-lg mb-4"></div>
      <div className="space-y-2">
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="h-8 bg-gray-200 rounded"></div>
        ))}
      </div>
    </div>
  );
};

export default PropertySkeleton;