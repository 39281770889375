import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-cover bg-center">
      <div className="bg-white bg-opacity-80 p-10 rounded-lg shadow-lg text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-600 mb-6">Page not found</h2>
        <p className="text-lg text-gray-500 mb-8">
          Sorry, we couldn't find the page you were looking for. This page may have been removed or its link may have
          changed.
        </p>
        <Link
          to="/"
          className="bg-blue-600 text-center text-white px-6 py-3 rounded-lg hover:bg-blue-500 transition-all flex items-center"
        >
          <FaHome className="mr-2" />
          Back To Home Page
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
