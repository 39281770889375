import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaPhoneAlt, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import whyDubai from "../Static/why-dubai.pdf";

export default function Footer() {
  return (
    <div className="relative top-36 bg-[#141413] py-16 text-white px-5">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          <p className="text-xl sm:text-2xl md:text-3xl uppercase font-semibold">
            Start your Journey with Advance Level
          </p>
          <a
            href="/filter"
            className="bg-gray-200 text-xs text-[#141413] mt-5 px-8 py-3 block w-fit mx-auto text-center uppercase tracking-widest rounded"
          >
            Find Property
          </a>
        </div>
        <hr className="border-gray-500" />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center lg:text-left my-10">
          <div>
            <p className="font-bold mb-3">SERVICES</p>
            <div className="grid grid-cols-1 gap-2">
              <a href="/investment-advisory">Investment Advisory</a>
              <a href="/property-management">Property Management</a>
              <a href="/property-valuation">Property Valuation</a>
              <a href="/holiday-homes">Holiday Homes And Renting</a>
            </div>
          </div>
          <div>
            <p className="font-bold mb-3">ABOUT</p>
            <div className="grid grid-cols-1 gap-2">
              <a href={whyDubai}>Why Dubai?</a>
              <a href="/investment-advisory">Why Advance Level ?</a>
              <a href="/our-partners">Our Partners</a>
            </div>
          </div>
          <div>
            <p className="font-bold mb-3">CONTACT</p>
            <a href="/contact">Contact Us</a>
          </div>
        </div>
        <hr className="border-gray-500" />
        <div className="flex flex-col lg:flex-row lg:justify-between items-center text-center lg:text-left gap-6 my-10">
          <div>
            <p className="font-extralight text-xs">© 2024 Advance Level Real Estate. All Rights Reserved.</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 text-xs">
            <p className="font-extralight flex items-center gap-2">
              <FaPhoneAlt />
              <a href="tel:+9756108400">+971582592907</a>
            </p>
            <p className="font-extralight flex items-center gap-2">
              <IoIosMail />
              <a href="mailto:info@advancelevelrealestate.com">info@advancelevelrealestate.com</a>
            </p>
          </div>
          <div className="flex gap-4">
            <a href="https://www.facebook.com/share/UBf5De2QdPQg7t3s" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-2xl" />
            </a>
            <a href="https://www.instagram.com/advance.level.real.estate" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-2xl" />
            </a>
            <a href="https://www.linkedin.com/in/advance-level-real-estate-615a5632b" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-2xl" />
            </a>
            <a href="https://youtube.com/@advancelevelrealestate" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="text-2xl" />
            </a>
            <a href="https://www.tiktok.com/@advance.levelrealestate?_t=8qD60q1BiTd&_r=1" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="text-2xl" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
