import React, { useState, useEffect } from "react";
import { BiCategoryAlt, BiDollar } from "react-icons/bi";
import {
  FaArrowsAlt,
  FaBed,
  FaBuilding,
  FaShower,
  FaWhatsapp,
  FaTimes,
  FaPhoneAlt,
  FaCheckCircle,
} from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { LuSend } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import Slider from "react-slick";
import Navbar from "../../Components/Navbar";
import PropertySkeleton from "../../Components/PropertySkeleton";
import { MdOutlineMail } from "react-icons/md";

function Property() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Modal للحالة الناجحة
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    customer_name: "",
    phone: "",
    customer_email: "",
    country_code: "+971", // قيمة افتراضية لكود الدولة
  });
  const countryCodes = [
    { name: "United States", code: "+1" },
    { name: "Canada", code: "+1" },
    { name: "United Kingdom", code: "+44" },
    { name: "Germany", code: "+49" },
    { name: "France", code: "+33" },
    { name: "Italy", code: "+39" },
    { name: "Spain", code: "+34" },
    { name: "Australia", code: "+61" },
    { name: "India", code: "+91" },
    { name: "China", code: "+86" },
    { name: "Japan", code: "+81" },
    { name: "Russia", code: "+7" },
    { name: "Brazil", code: "+55" },
    { name: "Mexico", code: "+52" },
    { name: "Argentina", code: "+54" },
    { name: "South Africa", code: "+27" },
    { name: "Nigeria", code: "+234" },
    { name: "Egypt", code: "+20" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "United Arab Emirates", code: "+971" },
    { name: "Qatar", code: "+974" },
    { name: "Kuwait", code: "+965" },
    { name: "Oman", code: "+968" },
    { name: "Bahrain", code: "+973" },
    { name: "Turkey", code: "+90" },
    { name: "Pakistan", code: "+92" },
    { name: "Bangladesh", code: "+880" },
    { name: "Malaysia", code: "+60" },
    { name: "Indonesia", code: "+62" },
    { name: "Philippines", code: "+63" },
    { name: "Thailand", code: "+66" },
    { name: "South Korea", code: "+82" },
    { name: "Vietnam", code: "+84" },
    { name: "New Zealand", code: "+64" },
    { name: "Singapore", code: "+65" },
    { name: "Hong Kong", code: "+852" },
    { name: "Israel", code: "+972" },
    { name: "Morocco", code: "+212" },
    { name: "Kenya", code: "+254" },
    { name: "Ghana", code: "+233" },
    { name: "Colombia", code: "+57" },
    { name: "Chile", code: "+56" },
    { name: "Venezuela", code: "+58" },
    { name: "Peru", code: "+51" },
    { name: "Bolivia", code: "+591" },
    { name: "Paraguay", code: "+595" },
    { name: "Uruguay", code: "+598" },
    { name: "Iraq", code: "+964" },
    { name: "Syria", code: "+963" },
    { name: "Jordan", code: "+962" },
    { name: "Lebanon", code: "+961" },
    { name: "Afghanistan", code: "+93" },
    { name: "Iran", code: "+98" },
    { name: "Sri Lanka", code: "+94" },
    { name: "Nepal", code: "+977" },
    { name: "Maldives", code: "+960" },
    { name: "Myanmar", code: "+95" },
    { name: "Cambodia", code: "+855" },
    { name: "Laos", code: "+856" },
    { name: "Mongolia", code: "+976" },
    { name: "Tanzania", code: "+255" },
    { name: "Uganda", code: "+256" },
    { name: "Zimbabwe", code: "+263" },
    { name: "Zambia", code: "+260" },
    { name: "Ethiopia", code: "+251" },
    { name: "Somalia", code: "+252" },
    { name: "Sudan", code: "+249" },
    { name: "Libya", code: "+218" },
    { name: "Tunisia", code: "+216" },
    { name: "Algeria", code: "+213" },
    { name: "Ivory Coast", code: "+225" },
    { name: "Cameroon", code: "+237" },
    { name: "Angola", code: "+244" },
    { name: "Senegal", code: "+221" },
    { name: "Mozambique", code: "+258" },
  ];

  const [isLoading, setIsLoading] = useState(true); // New state for loading

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    async function fetchProperty() {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/properties/${id}`);
        setProperty(response.data);
        setGallery(JSON.parse(response.data.image_gallery));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // إذا كان الرد 404، توجه المستخدم إلى صفحة Not Found
          navigate("/not-found");
        } else {
          alert("Something went wrong, please try again later.");
        }
      } finally {
        setIsLoading(false); // End loading
      }
    }
    fetchProperty();
  }, [id]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/make-order", {
        ...formData,
        property_id: id,
        customer_phone: formData.country_code + formData.phone,
      });
      setSuccessMessage("Thank you, one of our advisers will contact you shortly.");
      setIsSuccessModalOpen(true); // افتح مودال النجاح
      // Reset form after successful submission
      setFormData({
        customer_name: "",
        phone: "",
        customer_email: "",
        country_code: "+971", // إعادة تعيين كود الدولة إلى القيمة الافتراضية
      });
    } catch (error) {
      console.error("Error submitting booking:", error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false); // أغلق مودال النجاح
  };

  if (isLoading) {
    return (
      <>
        <Navbar />
        <div className="bg-g h-[2000px] top-20 max-w-7xl mx-auto p-6 relative text-black flex flex-wrap justify-between">
          <div className="w-[67%]">
            <PropertySkeleton />
          </div>
          <div className="w-[30%] h-fit sticky top-[100px] border border-gray-300 p-5">
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-32 bg-gray-200 rounded"></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div
        id="property-container"
        className="bg-g top-20 max-w-7xl mx-auto p-6 relative text-black grid grid-cols-1 lg:flex lg:flex-wrap justify-between"
      >
        <div className="property-info w-full lg:w-[67%]">
          <div className="prop-birf space-y-3">
            <p className="uppercase text-2xl font-semibold">{property.name}</p>
            <div className="icons-info flex gap-5 font-light flex-wrap">
              <p className="flex items-center gap-2">
                <FaBed />
                {property.number_of_rooms} Bedrooms
              </p>
              <p className="flex items-center gap-2">
                <FaShower />
                {property.number_of_bathrooms} Bathrooms
              </p>
              <p className="flex items-center gap-2">
                <FaArrowsAlt />
                {property.area_in_sqft} Sq.ft
              </p>
              <p className="flex items-center gap-2 font-bold border-b border-gray-800">
                <BiCategoryAlt />
                {property.category.name}
              </p>
            </div>
          </div>
          <hr className="mt-5 border-gray-300" />
          <div className="prop-images">
            <div className="slider-container">
              <Slider {...settings}>
                <img
                  src={`https://api.advancelevelrealestate.com/storage/${property.main_image}`}
                  alt="Property Main"
                  className="rounded h-[400px] w-[400px] object-cover"
                />
                {gallery.map((image, index) => (
                  <img
                    key={index}
                    src={`https://api.advancelevelrealestate.com/storage/${image}`}
                    alt={`Gallery Image ${index + 1}`}
                    className="rounded h-[400px] w-[400px] object-cover"
                  />
                ))}
              </Slider>
            </div>
          </div>
          <div className="prop-description mt-10">
            <p>{property.description}</p>
          </div>
          <hr className="mt-5 border-gray-300" />
          <p className="uppercase font-semibold text-2xl my-5">Details</p>
          <div className="prop-details grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <FaBed /> <p>Bedrooms</p>
              </div>
              <p>{property.number_of_rooms}</p>
            </div>
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <FaShower />
                <p>Bathrooms</p>
              </div>
              <p>{property.number_of_bathrooms}</p>
            </div>
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <FaArrowsAlt />
                <p>Size</p>
              </div>
              <p>{property.area_in_sqft} Sq.ft</p>
            </div>
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <FaBuilding />
                <p>Status</p>
              </div>
              <p>{property.status}</p>
            </div>
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <BiDollar />
                <p>Price</p>
              </div>
              <p>
                {Number(property.price).toLocaleString()} {property.currency}
              </p>
            </div>
            <div className="prop-detail flex justify-between py-4 border-b border-gray-300 text-sm">
              <div className="flex items-center gap-3">
                <IoLocation />
                <p>Location</p>
              </div>
              <div className="text-right">
                <p>{property.location}</p>
                <p>{property.location_ar}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="book w-full lg:w-[30%] h-fit sticky top-[100px] border border-gray-300 p-5">
          <p className="font-bold text-3xl mb-2">
            {property.currency} {Number(property.price).toLocaleString()}
          </p>
          <div className="flex gap-4 border-b border-gray-400 pb-4">
            <a
              href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
            >
              <FaWhatsapp className="mr-2" /> WHATSAPP
            </a>
            <a
              href="tel:+971582592907"
              className="flex items-center border rounded p-2 text-xs bg-gray-800 text-white border-gray-800 transition hover:bg-transparent hover:text-black mt-2"
            >
              <FaPhoneAlt className="mr-2" /> PHONE
            </a>
            <a
              href="mailto:info@advancelevelrealestate.com"
              className="flex items-center border rounded p-2 text-xs bg-gray-800 text-white border-gray-800 transition hover:bg-transparent hover:text-black mt-2"
            >
              <MdOutlineMail className="mr-2" /> EMAIL
            </a>
          </div>
          <div className="prop-form mt-3">
            <form onSubmit={handleSubmit} className="flex flex-col flex-wrap gap-4">
              <input
                type="text"
                name="customer_name"
                placeholder="Name"
                value={formData.customer_name}
                onChange={handleChange}
                required
                className="border border-gray-300 px-4 py-2"
              />
              <div className="flex items-center">
                <select
                  name="country_code"
                  className="border border-gray-300 rounded p-2 mr-2 w-1/3"
                  value={formData.country_code}
                  onChange={handleChange}
                >
                  {countryCodes.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone"
                  className="border border-gray-300 rounded p-2 w-2/3"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <input
                type="email"
                name="customer_email"
                placeholder="Email (Optional)"
                value={formData.customer_email}
                onChange={handleChange}
                className="border border-gray-300 px-4 py-2"
              />
              <button
                type="submit"
                className="bg-zinc-900 flex items-center justify-center text-white p-3 text-sm font-light border border-gray-400 rounded transition hover:bg-gray-800 mt-2"
              >
                <LuSend className="mr-2" /> Book Now
              </button>
            </form>
          </div>
        </div>

        {/* Modal لرسالة النجاح */}
        {isSuccessModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded-lg flex flex-col items-center">
              <FaCheckCircle className="text-green-500 text-4xl mb-4" />
              <p className="text-lg text-center">{successMessage}</p>
              <button onClick={closeSuccessModal} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Property;
