import React, { useEffect, useState } from "react";
import {
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaLinkedin,
  FaSearch,
  FaBuilding,
  FaHandshake,
  FaWhatsapp,
} from "react-icons/fa";
import { FaBuildingFlag, FaBuildingUser } from "react-icons/fa6";
import { Link } from "react-router-dom";
import whyDubai from "../Static/why-dubai.pdf";
import whyAdvance from "../Static/why-advance.pdf";

function Navbar({ isHome }) {
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState(null); // لتحديد القائمة الفرعية المفتوحة

  useEffect(() => {
    const handleScroll = () => {
      setNavbarScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSubMenu = (menu) => {
    setOpenSubMenus((prevMenu) => (prevMenu === menu ? null : menu)); // فتح القائمة المطلوبة وإغلاق الباقي
  };

  const isMobile = window.innerWidth <= 1024; // تحديد حالة العرض على الجوال
  let navClass = "";
  let logoColor = "";

  if (navbarScrolled || (isHome && menuOpen)) {
    navClass = "bg-black text-white px-5 py-3 ";
  } else if (isHome) {
    navClass = "bg-transparent p-5 text-white";
  } else {
    navClass = "bg-transparent p-5 text-black";
  }

  if (navbarScrolled) {
    logoColor = "/logo-white.png";
  } else if (isHome) {
    logoColor = "/logo-white.png";
  } else {
    logoColor = "/logo-black.png";
  }

  return (
    <>
      <nav
        className={`${navClass} fixed top-0 left-0 w-full z-40 flex justify-between items-center transition-colors duration-300`}
      >
        <a href="/">
          <img src={logoColor} alt="Logo" className="w-44 lg:w-[200px]" />
        </a>
        <div className="text-right">
          <button onClick={toggleMenu} className="text-xl focus:outline-none">
            {menuOpen ? "✖" : "☰"}
          </button>
          <a href="/contact" className="ml-10 uppercase">
            Contact
          </a>
        </div>
      </nav>

      <div
        className={`fixed right-0 bg-black text-white w-full h-screen lg:h-auto overflow-y-auto transform py-10 ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-50 p-5 ${navbarScrolled ? "top-16" : "top-20"}`}
      >
        <Link to="/filter" className="border px-2 py-1 rounded transition hover:bg-white hover:text-black">
          All Properties
        </Link>

        <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center mt-10 gap-8">
          {/* الخدمات */}
          <div className="w-full">
            <p
              onClick={() => isMobile && toggleSubMenu("services")}
              className="border-b border-gray-400 pb-4 lg:border-none cursor-pointer text-center"
            >
              SERVICES {isMobile && (openSubMenus === "services" ? "▲" : "▼")}
            </p>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                isMobile && openSubMenus !== "services" ? "max-h-0 opacity-0" : "max-h-96 opacity-100"
              }`}
            >
              <div className="grid grid-cols-2 lg:grid-cols-4 mt-10 gap-5 text-center">
                <Link to="/investment-advisory" className="flex flex-col items-center  rounded px-4 py-4 gap-y-2">
                  <FaBuilding className="text-2xl" />
                  <p>Investment Advisory</p>
                </Link>

                <Link to="/property-management" className="flex flex-col items-center  rounded px-4 py-4 gap-y-2">
                  <FaBuildingFlag className="text-2xl" />
                  <p>Property Management</p>
                </Link>

                <Link to="/property-valuation" className="flex flex-col items-center  rounded px-4 py-4 gap-y-2">
                  <FaBuildingUser className="text-2xl" />
                  <p>Property Valuation</p>
                </Link>

                <Link to="/holiday-homes" className="flex flex-col items-center  rounded px-4 py-4 gap-y-2">
                  <FaBuildingFlag className="text-2xl" />
                  <p>Holiday Homes And Renting</p>
                </Link>
              </div>
            </div>
          </div>

          {/* عننا */}
          <div className="w-full">
            <p
              onClick={() => isMobile && toggleSubMenu("about")}
              className="border-b border-gray-400 lg:border-none pb-4 cursor-pointer text-center"
            >
              About US {isMobile && (openSubMenus === "about" ? "▲" : "▼")}
            </p>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                isMobile && openSubMenus !== "about" ? "max-h-0 opacity-0" : "max-h-96 opacity-100"
              }`}
            >
              <div className="grid grid-cols-2 lg:grid-cols-3 mt-10 gap-5 text-center">
                <a href={whyDubai} className=" rounded h-[160px] py-4 flex flex-col items-center justify-center ">
                  <FaSearch />
                  <p className="mt-2">Why Dubai?</p>
                </a>
                <a href={whyAdvance} className=" rounded h-[160px] py-4 flex flex-col items-center justify-center ">
                  <FaSearch />
                  <p className="mt-2">Why Advance Level?</p>
                </a>
                <Link to="/our-partners" className=" rounded h-[160px] py-4 flex flex-col items-center justify-center ">
                  <FaHandshake />
                  <p className="mt-2">Our Partners</p>
                </Link>
              </div>
            </div>
          </div>

          {/* السوشيال */}
          <div className="w-full">
            <p
              onClick={() => isMobile && toggleSubMenu("social")}
              className=" border-b lg:border-none border-gray-400 pb-4cursor-pointer text-center"
            >
              Social {isMobile && (openSubMenus === "social" ? "▲" : "▼")}
            </p>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                isMobile && openSubMenus !== "social" ? "max-h-0 opacity-0" : "max-h-96 opacity-100"
              }`}
            >
              <div className="grid grid-cols-3 mt-20 gap-5 text-center place-items-center">
                <a href="https://www.facebook.com/share/UBf5De2QdPQg7t3s" target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="text-2xl" />
                </a>
                <a href="https://www.instagram.com/advance.level.real.estate" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="text-2xl" />
                </a>
                <a
                  href="https://www.linkedin.com/in/advance-level-real-estate-615a5632b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-2xl" />
                </a>
                <a href="https://youtube.com/@advancelevelrealestate" target="_blank" rel="noopener noreferrer">
                  <FaYoutube className="text-2xl" />
                </a>
                <a
                  href="https://www.tiktok.com/@advance.levelrealestate?_t=8qD60q1BiTd&_r=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok className="text-2xl" />
                </a>

                <a href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp className="text-2xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
