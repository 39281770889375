import React from "react";
import Navbar from "../Components/Navbar";
import propertyManagementBG from "../assets/images/property-management.webp";

function PropertyManagement() {
  return (
    <>
      <Navbar />
      <div
        className="top-28 relative text-white p-10 "
        style={{ backgroundImage: `url(${propertyManagementBG})`, backgroundSize: "cover" }}
      >
        <div className="container m-auto p-5 bg-black bg-opacity-55">
          <h1 className="text-center text-4xl font-semibold w-fit m-auto border-b-4 border-white pb-3">
            Property Management
          </h1>
          <div className="space-y-5 mt-12 text-lg">
            <h3>Benefits of the Property Management with Advance Level :</h3>

            <p>
              <strong>Property management</strong> is the process of overseeing and managing real estate properties.
              This can include residential properties, such as apartments and houses, as well as commercial properties,
              such as office buildings and retail centers.
            </p>

            <p>
              Property managers are responsible for a variety of tasks, including maintaining and repairing the
              property, collecting rent, screening tenants, and ensuring that the property is in compliance with local
              laws and regulations. They may also be responsible for marketing the property to potential tenants,
              negotiating leases, and handling the financial aspects of the property, such as budgeting and
              record-keeping. In some cases, property managers may also be responsible for managing the day-to-day
              operations of the property, including supervising staff and coordinating maintenance and repair work.
            </p>

            <p>There are several benefits of property management :</p>

            <p>
              <strong>Time-saving:</strong> Property management can save owners a significant amount of time, as they do
              not have to handle the day-to-day tasks associated with managing a property themselves.
              Expertise:Time-saving: Property management can save owners a significant amount of time, as they do not
              have to handle the day-to-day tasks associated with managing a property themselves
            </p>

            <p>
              <strong>Expertise:</strong> Property managers have expertise in the field of real estate management, and
              can provide valuable advice and guidance to owners.
            </p>

            <p>
              <strong>Improved cash flow:</strong> Property managers can help owners to optimize the performance of
              their property, resulting in improved cash flow. This can be achieved through effective rent collection,
              cost-effective maintenance and repairs, and by maximizing occupancy rates. Reduced liability: Property
              managers can help owners to minimize their liability by ensuring that the property is in compliance with
              local laws and regulations, and by screening tenants carefully to reduce the risk of legal issues.
            </p>

            <p>
              <strong>Increased value:</strong> By maintaining and improving the property, property managers can help
              owners to increase the value of their investment.
            </p>

            <p>
              <strong>Professionalism:</strong> Property managers can provide a professional, efficient service to
              tenants, improving the overall experience of renting the property and increasing the likelihood of
              retaining good tenants.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyManagement;
