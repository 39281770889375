import axios from "axios";

// إعداد Base URL
const axiosInstance = axios.create({
  baseURL: "https://api.advancelevelrealestate.com/api", // استبدلها برابط API الخاص بك
});


axios.defaults.withCredentials = true;
// إعدادات CORS (إذا لزم الأمر)

export default axiosInstance;
