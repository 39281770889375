import React, { useState, useEffect } from "react";
import axiosInstance from "../../axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { FaBed, FaBath, FaArrowsAlt, FaPhoneAlt, FaWhatsapp } from "react-icons/fa"; // إضافة أيقونات من React Icons
import FilterSkeleton from "../../Components/FilterSkeleton";
import { MdOutlineMail } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../../Components/Footer";

function Properties() {
  const [filters, setFilters] = useState({
    minPrice: 0,
    maxPrice: 99999999,
    category: "",
    bedrooms: null,
    bathrooms: null,
    status: null,
    size: null,
    location: "", // إضافة حقل للـ location
  });

  const [properties, setProperties] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]); // قائمة اقتراحات اللوكيشن
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // حالة التحميل

  // Fetch properties and categories when the component mounts
  useEffect(() => {
    fetchProperties();
    fetchCategories();
  }, []);

  // استخلاص الفلتر من الـ URL عند تحميل الصفحة
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filterLocation = params.get("keyword");
    if (filterLocation) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        location: filterLocation, // تعيين الفلتر المستخرج في حقل اللوكيشن
      }));

      fetchLocationSuggestions(filterLocation);
    }

    const filterStatus = params.get("status");
    if (filterStatus) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        status: filterStatus, // تعيين الفلتر المستخرج في حقل اللوكيشن
      }));
    }
  }, []);

  // Function to fetch categories from the API
  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Function to fetch filtered properties from the API
  const fetchProperties = async () => {
    try {
      const response = await axiosInstance.get("/properties", {
        params: filters,
      });
      setProperties(response.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsLoading(false); // إيقاف حالة التحميل بعد جلب البيانات
    }
  };

  // Function to fetch location suggestions
  const fetchLocationSuggestions = async (query) => {
    try {
      const response = await axiosInstance.get("/location-suggestions", {
        params: { query },
      });
      setLocationSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

  // Handle form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });

    // Fetch location suggestions if the field is location
    if (name === "location") {
      fetchLocationSuggestions(value);
    }
  };

  // Handle form submission (apply filters)
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchProperties(); // Fetch the filtered properties
  };

  return (
    <>
      <Navbar />
      <div className="max-w-8xl mx-auto p-6 relative top-20">
        <form
          onSubmit={handleFilterSubmit}
          className="flex flex-wrap gap-4 border-y border-gray-300 py-5 justify-center"
        >
          {/* فلتر اللوكيشن */}
          <div className="flex flex-col relative">
            <label className="text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              name="location"
              value={filters.location}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 w-80 "
              placeholder="Enter location"
            />
            {/* عرض اقتراحات اللوكيشن */}
            {locationSuggestions.length > 0 && (
              <ul className="border border-gray-300 p-2 mt-2 bg-white max-h-48 overflow-auto absolute top-14 z-40">
                {locationSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="cursor-pointer p-1 hover:bg-gray-100"
                    onClick={() => {
                      setFilters({ ...filters, location: suggestion });
                      setLocationSuggestions([]);
                    }}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* فلتر السعر */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Price Range</label>
            <div className="flex items-center">
              <input
                type="number"
                name="minPrice"
                placeholder="Min. Price"
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-32"
              />
            </div>
          </div>

          {/* فلتر الأقسام */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Category</label>
            <select
              name="category"
              value={filters.category}
              onChange={handleInputChange}
              className="p-2 w-44 rounded-lg border border-gray-300"
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          {/* فلتر حالة العقار  */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Status</label>
            <select
              name="status"
              value={filters.status}
              onChange={handleInputChange}
              className="p-2 w-44 border border-gray-300"
            >
              <option value="">Any</option>
              <option value="Ready To Move">Ready To Move</option>
              <option value="Off-Plan">Off Plan</option>
            </select>
          </div>

          {/* فلتر عدد الغرف */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Bedrooms</label>
            <select
              name="bedrooms"
              value={filters.bedrooms}
              onChange={handleInputChange}
              className="p-2 w-32 border border-gray-300"
            >
              <option value="">Any</option>
              {Array.from({ length: 10 }).map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          {/* فلتر عدد الحمامات */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Bathrooms</label>
            <select
              name="bathrooms"
              value={filters.bathrooms}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 w-32"
            >
              <option value="">Any</option>
              {Array.from({ length: 10 }).map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          {/* فلتر حجم العقار */}
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700">Property Size (sqm)</label>
            <input
              type="number"
              name="size"
              value={filters.size || ""}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 w-32"
              placeholder="e.g., 100"
            />
          </div>

          {/* زر البحث */}
          <div className="flex items-end">
            <button type="submit" className="bg-gray-800 text-white px-5 py-3 rounded">
              Find
            </button>
          </div>
        </form>

        {/* عرض العقارات */}
        <div className="properties-list mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading ? (
            Array.from({ length: 6 }).map((_, index) => <FilterSkeleton key={index} />) // عرض مكونات Skeleton عند التحميل
          ) : properties.length > 0 ? (
            properties.map((property) => (
              <div
                key={property.id}
                className="border p-4 rounded cursor-pointer relative"
                onClick={() => navigate(`/property/${property.id}`)}
              >
                <p className="bg-gray-800 text-white text-sm absolute p-3 rounded right-8 top-8">
                  {property.category.name}
                </p>

                <LazyLoadImage
                  src={`https://api.advancelevelrealestate.com/storage/${property.main_image}`}
                  alt={property.name}
                  className="h-64 w-full object-cover"
                  effect="opacity" // تأثير التحميل
                />
                <h3 className="font-bold text-lg mt-4">{property.name}</h3>
                <p>
                  {Number(property.price).toLocaleString()} {property.currency} | {property.status}
                </p>
                <div className="propinfo flex flex-wrap gap-5">
                  <div className="flex items-center gap-2 mt-2">
                    <FaBed />
                    <p>{property.number_of_rooms} Bedrooms</p>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <FaBath />
                    <p>{property.number_of_bathrooms} Bathrooms</p>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <FaArrowsAlt />
                    <p>{property.area_in_sqft} sqm</p>
                  </div>
                  <div className="flex gap-4 items-center justify-center">
                    <a
                      href="https://wa.me/971585408409/?text=I would like to inquire about your property in UAE"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                    >
                      <FaWhatsapp className="mr-2" /> WHATSAPP
                    </a>
                    <a
                      href="tel:+971582592907"
                      className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                    >
                      <FaPhoneAlt className="mr-2" /> PHONE
                    </a>
                    <a
                      href="mailto:info@advancelevelrealestate.com"
                      className="flex items-center border rounded p-2 text-xs border-gray-800 transition hover:bg-gray-800 hover:text-white mt-2"
                    >
                      <MdOutlineMail className="mr-2" /> EMAIL
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No properties found.</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Properties;
