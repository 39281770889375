import React from "react";
import Navbar from "../Components/Navbar";
import InvestmentBG from "../assets/images/investment-advisory.webp";

function InvestmentAdvisory() {
  return (
    <>
      <Navbar />
      <div className="top-28 relative text-white p-10 " style={{ backgroundImage: `url(${InvestmentBG})`, backgroundSize: "cover" }}>
        <div className="container m-auto p-5 bg-black bg-opacity-55">
          <h1 className="text-center text-4xl font-semibold w-fit m-auto border-b-4 border-white pb-3">
            Investment Advisory
          </h1>
          <div className="space-y-5 mt-12 text-lg">
            <h3>Get investment advice at Advance Level</h3>
            <p>There are a few different ways to get investment advisory services:</p>
            <p>
              <strong>Financial advisors:</strong> You can work with a financial advisor who can provide investment
              advice and help you create a financial plan that meets your goals. Financial advisors can be found at
              financial planning firms, banks, and brokerage firms.
            </p>
            <p>
              <strong>Robo-advisors:</strong> Another option is to use a robo-advisor, which is a digital platform that
              provides automated, algorithm-driven investment advice with little to no human interaction.
            </p>
            <p>
              <strong>Online investment platforms:</strong> Some online investment platforms, such as Motif and M1
              Finance, offer investment advice and portfolio management services. These platforms typically have lower
              fees than traditional financial advisors and can be a good option for those who are comfortable managing
              their investments but want some guidance.
            </p>
            <p>
              <strong>Investment clubs:</strong> You can also join an investment club, which is a group of individuals
              who pool their money and make joint investment decisions.
            </p>
            <p>
              Before choosing an investment advisor, it's important to do your due diligence and research their
              qualifications, experience, and fee structure. You may also want to consider working with a fee-only
              advisor, who is paid only by their clients and does not receive commissions for selling financial
              products.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestmentAdvisory;
