import React from "react";
import Navbar from "../Components/Navbar";

import properyValuation from "../assets/images/property-valuation.jpg";

function PropertValuation() {
  return (
    <>
      <Navbar />
      <div
        className="top-28 relative text-white p-10 "
        style={{ backgroundImage: `url(${properyValuation})`, backgroundSize: "cover" }}
      >
        <div className="container m-auto p-5 bg-black bg-opacity-55">
          <h1 className="text-center text-4xl font-semibold w-fit m-auto border-b-4 border-white pb-3">
            Property Valuation
          </h1>
          <div className="space-y-5 mt-12 text-lg">
            <h3>Get the Right Valuation of your Property in Dubai</h3>
            <p>
              <strong>Property valuation</strong> is the process of determining the value of a piece of real estate.
              There are several approaches to property valuation, including the market approach, the cost approach, and
              the income approach.
            </p>
            <p>
              <strong>The market approach</strong> involves comparing the subject property to similar properties that
              have recently sold in the area.
            </p>
            <p>
              <strong>The cost approach</strong> involves estimating the value of the property based on the cost to
              rebuild it.
            </p>
            <p>
              <strong> The income approach</strong> involves estimating the value of the property based on the income it
              is expected to generate. This approach is commonly used for income-producing properties, such as rental
              properties, and is based on the principle of capitalization, which states that the value of a property is
              equal to the present value of its future cash flows.
            </p>
            <p>
              <strong>Property valuations</strong> are typically performed by professional appraisers, who use a
              combination of these approaches to determine the value of a property. The results of a property valuation
              can be used for a variety of purposes, including determining the value of a property for sale or purchase,
              setting property taxes, and determining the amount of insurance coverage needed for a property.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertValuation;
