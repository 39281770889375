import React from "react";

const FilterSkeleton = () => {
  return (
    <div className="border p-4 rounded animate-pulse">
      <div className="h-64 w-full bg-gray-300 rounded"></div>
      <div className="mt-4 h-6 bg-gray-300 rounded w-3/4"></div>
      <div className="mt-2 h-4 bg-gray-300 rounded w-1/4"></div>
      <div className="flex flex-wrap gap-5 mt-4">
        <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        <div className="h-4 bg-gray-300 rounded w-1/4"></div>
      </div>
    </div>
  );
};

export default FilterSkeleton;
