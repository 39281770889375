import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import emaar from "../assets/partners/emaar.webp";
import damac from "../assets/partners/damac.webp";
import nakheel from "../assets/partners/nakheel.webp";
import aziz from "../assets/partners/azizi.webp";
import aldar from "../assets/partners/aldar.jpg";
import danube from "../assets/partners/danube.png";
import dubaiProperties from "../assets/partners/dubai-properties.webp";
import arada from "../assets/partners/arada.jpg";
import sobha from "../assets/partners/sobha.webp";
import meraas from "../assets/partners/meraas.webp";
import nshama from "../assets/partners/nshama.webp";
import tiger from "../assets/partners/tiger.webp";

function OurPartners() {
  const [developerBreif, setDeveloperBreif] = useState({
    image: "",
    describe: "",
  });
  const [overlayOpen, setOverlayOpen] = useState(false);
  const partners = [
    {
      img: emaar,
      name: "EMARR",
      desc: "Emaar Properties is one of the world’s most valuable and admired real estate development companies. With proven competencies in properties, shopping malls & retail and hospitality & leisure, Emaar shapes new lifestyles with a focus on design excellence, build quality and timely delivery.",
    },
    {
      img: damac,
      name: "DAMAC",
      desc: "DAMAC Properties has been at the forefront of the Middle East’s luxury real estate market since 2002, delivering luxury residential, commercial and leisure properties across the region, including the UAE, Saudi Arabia, Qatar, Jordan, Lebanon and the United Kingdom.",
    },
    {
      img: nakheel,
      name: "NHAKHEEL",
      desc: "Nakheel is a world-leading developer with innovative landmark projects in Dubai across the residential, retail, hospitality and leisure sectors. Our master developments include the iconic and award-winning Palm Jumeirah as well as The World, Jumeirah Islands, Jumeirah Village, Jumeirah Park, Al Furjan, The Gardens, Discovery Gardens, Jebel Ali Village, Dragon City, International City and the upcoming Deira Islands. Together, these span more than 15,000 hectares and currently provide homes for over 300,000 people. Nakheel Malls, the company’s retail arm, owns and operates a range of assets including large-scale malls, dining and entertainment destinations, and community shopping centres. Nakheel Hospitality’s project portfolio comprises a range of hotels and resorts as well as community clubs, restaurants and lounges. Nakheel’s residential leasing portfolio currently comprises 17,000 villas and apartments across Dubai.",
    },
    {
      img: aziz,
      name: "AZIZ",
      desc: "Azizi Developments is a leading developer based in Dubai with an extensive portfolio of modern luxury residential and commercial properties across the emirate’s most sought-after locations. Our properties, through which we aim to enrich the lives of our residents and investors, cater to all lifestyles. We have delivered thousands of homes, have over 54 ongoing projects that will be delivered by 2023, and another 130+ in planning that are projected to be delivered by 2025.",
    },
    {
      img: aldar,
      name: "ALDAR",
      desc: "Aldar Properties PJSC is the leading real estate developer in Abu Dhabi, and through its iconic developments, it is one of the most well known in the United Arab Emirates, and wider Middle East region. Aldar develops exciting and innovative projects, such as the internationally recognized HQ building situated in the Al Raha Beach development, the Gate Towers in Shams Abu Dhabi on Al Reem Island, in addition to Yas Island’s F1 circuit.Aldar seeks to create quality, comfortable, desirable destinations that enrich the lives of Abu Dhabi residents as well as tourists within the Emirate. Aldar is playing a leading role in the development and provision of world class retail – Yas Mall, international standard education through Aldar Academies, iconic entertainment venues such as the Yas Marina Circuit, and community amenities across its entire portfolio.",
    },
    {
      img: danube,
      name: "Danube",
      desc: "Danube Group has grown from a single store started in Deira, Dubai 25 years ago to one of the most trusted and household brand in the Middle-East. The multi-million dollar Group has been consistently increasing its global footprint and annual revenue supported by ever-growing family of over 2,500 staff. Danube Group is head-quartered in Dubai and operates in 9 countries across Middle-East & Asia. Danube Properties the property development arm of the business is rated amongst top 5 developers in the UAE. Company’s major achievement include on time delivery of exceptional quality assets with record sales success.",
    },
    {
      img: dubaiProperties,
      name: "Dubai Properties",
      desc: "Dubai Properties is a leading real estate master developer based in the United Arab Emirates and is responsible for shaping some of Dubai’s most renowned and iconic real estate destinations. Playing a leading role in driving the successful development and diversification of one of the most vital sectors in the economy, Dubai Properties is committed to creating residential developments across the Emirate that provide distinctive and enriching lifestyles for residents. With over 16 years of experience in the residential real estate sector, Dubai Properties has an in-depth understanding of the core needs of home owners and international investors and has been providing innovative real estate and lifestyle solutions for years. Dubai Properties’ versatile portfolio of projects spans the Emirate from Jumeirah Beach Residence (JBR) and Business Bay to DUBAILAND, Jaddaf Waterfront and beyond.",
    },
    {
      img: arada,
      name: "Arada",
      desc: "Arada was created to build communities that enrich, engage and inspire their residents. Our homes are exceptionally designed and are complemented with best-in-class amenities, all provided at an accessible price point. Our prime focuses are on the intersection of community, technology and sustainability – all critical and fast-moving issues in the world today – and how these three themes can improve the lives of residents, workers and visitors in Arada’s developments. The focus of our first year of operations has been the Emirate of Sharjah. Our objective is to deliver the kind of communities that Sharjah deserves, and to aid in its transformation to become a truly global city. The two communities that we have launched so far, Aljada and Nasma Residences, are fulfilling that goal, generating significant interest from various stakeholders, including investors, end users, partners in both the private and the public sector and the media.",
    },
    {
      img: sobha,
      name: "Sobha",
      desc: "Sobha Realty is an international luxury developer committed to redefining the art of living through sustainable communities. Established in 1976 as an interior decoration firm in Oman by PNC Menon – a visionary entrepreneur, the company has grown its presence with developments and investments in the UAE, Oman, Bahrain, Brunei and India. Over the last four decades, Sobha Realty has also redefined the real estate value chain by leveraging its inherent in-house capabilities of conceptualisation, design and development. Sobha Realty is currently developing Sobha Hartland, a luxurious freehold community spread across eight million square feet in the heart of Dubai, as part of the Mohammed Bin Rashid Al Maktoum City master development.",
    },
    {
      img: meraas,
      name: "Meraas",
      desc: "Meraas’ portfolio in real estate comprises property development sales and asset management across some of Dubai’s most sought-after and iconic locations, such as City Walk, Bluewaters, Jumeira Bay, Pearl Jumeira and La Mer. The company prides itself on working with the best in class partners with a commitment to building projects of the highest quality, delivered on time, using the most comprehensive and integrated master planning. It also boasts a significant land bank in key areas in Dubai.",
    },
    {
      img: nshama,
      name: "Nshama",
      desc: "Nshama is a Dubai-based developer of integrated lifestyle communities offering exceptional value to aspiring homeowners since 2014. The company has established itself at the forefront of residential property development in the UAE, through the runaway success of its flagship project Town Square Dubai. Nshama projects are truly self-contained and offer residents all their needs in close proximity. Its projects are differentiated at all touch points – from value to amenities, location and sustainability considerations. Investing in Nshama enables customers to shift their lifestyle from a predominantly rent-driven model to an own end-user home that is cost-competitive and a solid investment for the family.",
    },
    {
      img: tiger,
      name: "Tiger",
      desc: "Tiger Group Properties Companies have been managing the development of thousands of Residential, Commercial and Retail Units according to the best practices and the high-quality standards and in compliance with the local legislations and codes to ensure the optimum satisfaction of our customers.",
    },
  ];

  function handleClick(img, desc) {
    setDeveloperBreif({ img, describe: desc });
    setOverlayOpen(true);
  }

  function handleOverlay() {
    setOverlayOpen(!overlayOpen);
    console.log(overlayOpen);
  }
  return (
    <>
      <Navbar />
      <div className="top-28 relative container m-auto px-5">
        <h1 className="text-center text-4xl font-semibold w-fit m-auto border-b-4 border-gray-800 pb-3">
          Our Partners
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mt-10">
          {partners.map((partner, index) => (
            <div
              key={index}
              onClick={() => handleClick(partner.img, partner.desc)}
              className="partner-item cursor-pointer border border-gray-200 flex items-center justify-center py-5"
            >
              <img src={partner.img} alt={partner.name} width={150} />
            </div>
          ))}
        </div>
        {overlayOpen && (
          <div className="partner-desc fixed bg-black bg-opacity-80 w-full h-full top-0 left-0 flex items-center justify-center">
            <div className="bg-white relative px-10 py-5 rounded max-w-3xl space-y-5 ">
              <button className="absolute right-10 text-2xl" onClick={handleOverlay}>
                X
              </button>
              <img src={developerBreif.img} alt="" width={150} />
              <p>{developerBreif.describe}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OurPartners;
